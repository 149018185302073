import React, { useState } from 'react';
import Button from '../utils/Button';
import './Header.css';
import { showLoginForm, showRegisterForm } from './../../controllers/AppConstants';

function Header(props) {
    return (
        <div className="header">
            <div className="container">
                <div className="row" style={{ padding: '20px 0px' }}>
                    <div className="col-5 d-table">
                        <a href="/" className='logo d-table-cell align-middle'>
                            Logo Here
                        </a>
                    </div>
                    <div className='col-7 d-table'>
                        {(props.isLoggedIn == false) ? (
                            // If user is not logged in
                            <div className='d-flex justify-content-end'>
                                <Button name="Login" class="btn btn-outline-warning" onClick={showLoginForm} />
                                <Button name="Register" style={{ marginLeft: "10px" }} onClick={showRegisterForm} />
                            </div>
                        ) : (
                            // Show when user is not logged in
                            <nav className="navbar navbar-expand float-right navbar-dark" style={{ padding: "0px" }}>
                                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src="https://s3.eu-central-1.amazonaws.com/bootstrapbaymisc/blog/24_days_bootstrap/fox.jpg" width="40" height="40" className="rounded-circle float-left" />
                                        </a>
                                        <ul className="dropdown-menu" style={{ marginLeft: "-90px", marginTop: "10px" }} aria-labelledby="navbarDropdown">
                                            <li><a className="dropdown-item" href="#">Dashboard</a></li>
                                            <li><a className="dropdown-item" href="#">Settings</a></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            <li><a className="dropdown-item" href="#" onClick={props.onLogout}>Logout</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </nav>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
