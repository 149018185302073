import React from 'react';
import './Footer.css';

function Footer(props) {
    return (
        <div className='footerSection'>
            {//Footer icons
            (props.elements && props.elements.indexOf('footerIcons') != -1)?(
                <div className="container footerIcons">
                    <div className="row" >
                        <div className="col-lg-10 col-md-12 col-12 ">
                            <div className="row">
                                <div className="col-md-3 col-6 marginTop30 paddingTop10 d-flex">
                                    <div className="icon iconAge float-left">
                                        18+
                                    </div>
                                    <div className='float-left pl20'>
                                        Age <br/>
                                        Limit
                                    </div>
                                    <div className='clearBoth'></div>
                                </div>
                                <div className="col-md-3 col-6 marginTop30 paddingTop10 d-flex">
                                    <div className="icon icon247 float-left">
                                        24/7
                                    </div>
                                    <div className='float-left pl20'>
                                        Customer <br/>
                                        Support
                                    </div>
                                    <div className='clearBoth'></div>
                                </div>
                                <div className="col-md-3 col-6 marginTop30 paddingTop10 d-flex">
                                    <div className="icon iconSecurePayments float-left">
                                        <i className="bi bi-lock"></i>
                                    </div>
                                    <div className='float-left pl20'>
                                        Secure <br/>
                                        Payments
                                    </div>
                                    <div className='clearBoth'></div>
                                </div>
                                <div className="col-md-3 col-6 marginTop30 paddingTop10 d-flex">
                                    <div className="icon iconDataProtection sfloat-left">
                                        <i className="bi bi-shield-check"></i>
                                    </div>
                                    <div className='sfloat-left pl20'>
                                        Data <br/>
                                        Protected
                                    </div>
                                    <div className='clearBoth'></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-12 col-12 text-center marginTop30 paddingTop0">
                            <span className='colinasRegular lightBlue' style={{fontSize: "25px"}}>Indian<br />Roulette</span>
                        </div>
                    </div>
                </div>
            ):('')}

            
            {//Disclaimer section
            (props.elements && props.elements.indexOf('disclaimer') != -1)?(
                <React.Fragment>
                    <div className='container seperator'></div>

                    <div className='disclaimer'>
                        <div className="container">
                            <div className='row'>
                                <div className='col-md-1'></div>
                                <div className='clo-12 col-md-10'>
                                    <span className='colinasRegular lightBlue'>Indian Roulette</span> is licensed and regulated 
                                    to offer online casino gaming services under the jurisdiction of India. 
                                    &#169;2022 <span className='colinasRegular lightBlue'>Indian Roulette</span> is an internationally 
                                    registered trademark. 
                                    <span className='colinasRegular lightBlue'>Indian Roulette</span> is operated by Indian Online Casino Pvt Ltd 
                                    of 741 New Friends Colony, New Delhi under a license issued by the Indian Licensing Authority 
                                    and regulated by the Indian Gambling Commissioner. Only players above the age of 18 are permitted 
                                    to play our games. www.yourdomain.com
                                </div>
                                <div className='col-md-1'></div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ):('')}

            
            {//Copyright strip
            (props.elements && props.elements.indexOf('copyright') != -1)?(
                <div className='copyright'>
                    <div className='container'>
                        &#169; Copyright 2022 | All right reserved <span className='colinasRegular offWhite'>Indian Roulette</span>
                    </div>
                </div>
            ):("")}
        </div>
    );
}

export default Footer;