import './Body.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";


function GameCard(props){
    return (
        <div className="col-12 col-md-6 col-lg-3">
            <div className="card">
                <img className="card-img-top" src={props.game["thumbnail"]} alt={props.game["name"]} />
                <div className="card-body">
                    <h5 className="card-title">{props.game["name"]}</h5>
                    <p className="card-text" style={{height:'80px'}}>{props.game["description"]}</p>
                    {(props.game["status"] === "active")?(
                        <Link to={'game/'+props.game["id"]} className="btn btn-warning">Play Now</Link>
                    ):(
                        <span className="btn btn-outline-dark disabled">Coming Soon</span>
                    )}
                </div>
            </div>
        </div>
    );
}

function Body(props){
    return (
        <div className='cardsContainer zoomEffect hueEffect'>
            <div className="container d-flex flex-wrap">
                {Object.keys(props.games).map((gameId, i)=>{
                    let gameItem = props.games[gameId];
                    return <GameCard key={i} game={gameItem} />
                })}
            </div>
        </div>
    );
}

export default Body;