import './Banner.css';

function Banner() {
    return (
        <div className="banner">
            <div className="container">
                <div className="row" style={{ paddingTop: '0px' }}>
                    <div className=" col-12 col-lg-6 ">
                        <img src="./../assets/banner/graphic02.png" style={{ width: '100%' }} />
                    </div>
                    
                    <div className="col-12 col-lg-6  text-center d-table">
                        <div className='d-table-cell align-middle'>
                            <div style={{fontFamily:"ColinasRegular", color: "#FFC107", fontSize: '50px' }}>
                                Indian Roulette
                            </div>
                            <div style={{ fontSize: '30px', marginTop: '10px' }}>
                                <b>
                                    New Players get 150% up to ₹15,000 & ₹101 daily for 7 Days for luck*
                                </b>
                            </div>
                            <button type="button" className="btn btn-outline-warning text" style={{ borderRadius: '50px', padding: '10px 30px', fontSize: '16px', marginTop: '20px' }}>
                                Deposit Now
                            </button>
                        </div>
                    </div>
                </div>
                <div className='row' style={{paddingBottom: '30px', paddingTop: '20px', fontSize: '10px' }}>
                    <div className="col-12 text-left">
                        *18+. New customers. Opt-in required. 150% Deposit Bonus : 150% up to ₹15,000. Min Dep ₹1,000. ₹101 Daily Cash back : ₹101 daily for 7 Days. Min dep ₹1,500. 30x bonus wagering (game weighting applies). T&Cs apply, Play responsibly. Jocularis Ltd is licensed by Govt. of Gibraltar (Remote Gaming Licences #126/127) Regulated by Gibraltar Gambling Commissioner | begambleaware.org
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Banner;