import React, { useState } from 'react';
import './Forms.css';
import { closeAllForms } from './../../controllers/AppConstants';

function Login(props) {

    function login(){
        let email = document.getElementById("email").value;
        let password = document.getElementById("password").value;
        let returnData = false;
        if(email == "test@testing.com" && password == "test"){
            returnData = true;
            closeAllForms();
        }
        props.onLogin(returnData);
    }
    return (
        <div id="loginForm" className="modal fade" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">

                    <div className="modal-header" style={{color: 'black'}}>
                        <div className="col-3" style={{paddingTop: '7px'}}>
                            ← Register
                        </div>
                        
                        <div className="col-6 text-center">
                            <a href="#" className='logo black'>Logo Here</a>
                        </div>

                        <div className="col-3 d-flex justify-content-end" >
                            <button type="button" className="btn btn-white" data-bs-dismiss="modal">✖</button>
                        </div>
                    </div>

                    <div className="modal-body">
                        <div className="container " style={{ fontSize: '20px', margin: '30px auto' }}>
                            <div className="text-center">
                                Log in to your account
                            </div>
                            
                            <form style={{ margin: '30px auto'}}>
                                <input type="text" className="form-control" id="email" placeholder="Email" valvue="test@testing.com" required />
                                <br />
                                <input type="password" className="form-control" id="password" placeholder="Password" valvue="test" required />
                            </form>
                            
                            <div style={{fontSize: '14px', textAlign: 'center'}}>
                                <label className="custom-control custom-checkbox checkbox-lg" style={{width: '160px', margin: 'auto', marginBottom:'20px'}}>
                                    <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                    <div className="custom-control-label" htmlFor="customCheck1" style={{display: 'inline-block', marginLeft:'5px'}}>Remember Password</div>
                                </label>
                                <br />
                                <a href="#" target="_blank">Forgot Password?</a>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-warning" onClick={login} style={{ borderRadius: '50px', width: '250px', margin: '10px auto', padding: '10px 0px' }}>LOGIN</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;