import defaultConfig from "./config/config.json";
import devConfig from "./config/dev.json";
import testConfig from "./config/test.json";
import prodConfig from "./config/prod.json";

function overrrideEnvConfig(_env){
    let tempConfig = JSON.parse(JSON.stringify(defaultConfig));
    let overrrideConfig = defaultConfig;
    if(_env === 'dev'){
        overrrideConfig = devConfig;
    }else if(_env === 'test'){
        overrrideConfig = testConfig;
    }else if(_env === 'prod'){
        overrrideConfig = prodConfig;
    }
    //
    Object.keys(overrrideConfig).forEach(key => {
        tempConfig[key] = overrrideConfig[key];
    });
    return tempConfig;
}

export const ENV = process.env.REACT_APP_ENV;
export const CONFIG = overrrideEnvConfig(ENV);