import './BannerBottom.css';

function BannerBottom() {
    return (
        <div style={{ backgroundColor: "#8491bc", color: "#061130", padding: "60px 0px", textAlign: "left" }}>
            <div className="container">
                <div className="row">

                    <div className="col-md-4 col-12">
                        <div className='row marginTop10 marginBottom10'>
                            <div className='col-3 col-md-12 col-lg-4'>
                                <div className="circle">1</div>
                            </div>
                            <div className='circleText col-9 col-md-12 col-lg-8'>
                                <span style={{fontWeight: "bold", fontSize: "20px" }}>Sign up</span><br />
                                Create a free account. +18 (or min. age of the territory you're playing in).
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-4 col-12">
                        <div className='row marginTop10 marginBottom10'>
                            <div className='col-3 col-md-12 col-lg-4'>
                                <div className="circle">2</div>
                            </div>
                            <div className='circleText col-9 col-md-12 col-lg-8'>
                                <span style={{fontWeight: "bold",  fontSize: "20px" }}>MAKE A DEPOSIT</span><br />
                                New player? Grab your juicy welcome Offer on first deposit.
                            </div>
                        </div>
                    </div>
                                        
                    <div className="col-md-4 col-12">
                        <div className='row marginTop10 marginBottom10'>
                            <div className='col-3 col-md-12 col-lg-4'>
                                <div className="circle">3</div>
                            </div>
                            <div className='circleText col-9 col-md-12 col-lg-8'>
                                <span style={{fontWeight: "bold",  fontSize: "20px" }}>START PLAYING</span><br />
                                Find your favourite game and have fun!
                            </div>
                        </div>
                    </div>
                    

                    {/* <div className="col-lg-4 col-md-4 col-12">
                        <table  style={{margin: "20px"}}>
                            <tr>
                                <td>
                                    <div className="circle">
                                        1
                                    </div>
                                </td>
                                <td style={{ paddingLeft: "20px" }}>
                                    <span style={{ fontSize: "20px" }}> <b>Sign up</b>
                                    </span><br />
                                    Create a free account. +18 (or min. age of the territory you're playing in).
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                        <table  style={{margin: "20px"}}>
                            <tr>
                                <td>
                                    <div className="circle">
                                        2
                                    </div>
                                </td>
                                <td style={{ paddingLeft: "20px" }}>
                                    <span style={{ fontSize: "20px" }}>
                                        <b>MAKE A DEPOSIT</b>
                                    </span>
                                    <br />
                                    New player? Grab your juicy<br />
                                    Welcome Offer on first deposit.
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                        <table  style={{margin: "20px"}}>
                            <tr>
                                <td>
                                    <div className="circle">
                                        3
                                    </div>
                                </td>
                                <td style={{ paddingLeft: "20px" }}>
                                    <span style={{ fontSize: "20px" }}> <b>START PLAYING</b>
                                    </span><br />
                                    Find your favourite game<br />
                                    and have fun!
                                </td>
                            </tr>
                        </table>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default BannerBottom;
